
const input = document.createElement('input');
input.type = 'text';
input.name = 'priority';
input.id = 'priority';
input.setAttribute('data-label', 'priority');
input.value = '3K';
input.style = "display:none;"

document.addEventListener('DOMContentLoaded', () => {
    const form = document.getElementsByTagName('form')[0];
    form.appendChild(input);
});
window.addEventListener('heyflow-change', (event) => {
    console.log('heyflow-change', event.detail.fieldsSimple);
    if (event.detail.fieldsSimple.hasHearingAid == 'true'){
        switch(event.detail.fieldsSimple.howOldIsHearingAid){
        case '5':
            input.value = '1B';
            break;
        case '4':
            if (event.detail.fieldsSimple.whichInsuranceType == 'PRIVATE'){
            input.value = '1C';
            break;}
            else{
            input.value = '3G'
            break;    
            }
        case '3':
            if (event.detail.fieldsSimple.whichInsuranceType == 'PRIVATE'){
            input.value = '3G';
            break;}
            else{
               input.value = '3H';
            break; 
            }
        case '2':
             if (event.detail.fieldsSimple.whichInsuranceType == 'PRIVATE'){
            input.value = '3H';
            break;}
            else{
               input.value = '3I';
            break; 
            }
        case '1':
             if (event.detail.fieldsSimple.whichInsuranceType == 'PRIVATE'){
                input.value = '3I';
                break;}
            else{
                input.value = '3J';
                break; 
            }
        }
        
    }
    else{
    
    if (event.detail.fieldsSimple.hasENTPrescription == 'true')
                input.value = '1E';
    else

    if (event.detail.fieldsSimple.userAge > 49) 
                input.value = '1F';
    
    } 
    
});